<template>
  <div class="q-position-relative">
    <video
      style="max-width: 200px;"
      class="qchat-conv__bubble__image qchat-conv__rounded__video q-pointer"
      controls
      disablepictureinpicture
      controlslist="nodownload noremoteplayback noplaybackrate"
    >
      <source :src="message.file.url" />
    </video>
    <div
      class="qon-conv__bubble__btn-video q-pointer"
      @click="downloadVideo(message.file.url)"
    >
      <Icons type="la-download" :size="'15'" />
    </div>
    <p v-if="message.text" class="qchat-conv__bubble__message q-mb-0 q-mt-2">
      <!-- <span
        v-dompurify-html="formattedMessage"
        style="white-space: pre-line"
      ></span> -->
      <span v-for="(content, idx) in contentFormatter(message.text)" :key="idx">
        <span
          v-dompurify-html="bracketClear(whatsappFormatter(content.textBefore))"
        ></span
        ><a
          :href="
            content.url.includes('://') ? content.url : `https://${content.url}`
          "
          style="word-break: break-all;"
          target="_blank"
          >{{ content.url }}</a
        ><span
          v-dompurify-html="bracketClear(whatsappFormatter(content.textAfter))"
        ></span>
      </span>
      <span
        v-if="message.text.length >= 253 && !isRead"
        class="q-pointer"
        @click="isRead = true"
        :style="{
          color:
            styles.conversation.bubbleChat[
              user.name === message.sender.name ? 'right' : 'left'
            ].mainColor === '#0474f5'
              ? '#234d8d'
              : '#0274f5'
        }"
        >Read more</span
      >
    </p>
  </div>
</template>
<script>
const Icons = () => import("../../Icons/index.vue");
import { mapState } from "vuex";

export default {
  components: { Icons },
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isRead: true
    };
  },
  computed: {
    ...mapState("messenger", ["styles", "user"]),
    formattedMessage() {
      if (this.isRead)
        return this.whatsappToPreviewFormatter(this.message.text);
      else
        return this.whatsappToPreviewFormatter(
          this.sliceWords(this.message.text, 253)
        );
    }
  },
  methods: {
    createObjectURL(file) {
      return URL.createObjectURL(file);
    },
    downloadVideo(url) {
      window.open(url);
    }
  }
};
</script>
